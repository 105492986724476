import React from "react"
import PropTypes from "prop-types"

const Title = ({ title, titleClass, subtitle, isExcluded, ...props }) => {
  if (props.className === undefined) {
    props.className = "page-title"
  } else {
    props.className += " page-title"
  }

  const typeSense = isExcluded ? {} : { "data-typesense-field": "title" }

  return (
    <div {...props}>
      <h1
        className={`title h1${subtitle ? ` mb-2` : ``} ${titleClass}`}
        {...typeSense}
      >
        <span>{title}</span>
      </h1>
      {subtitle && <div className="subtitle">{subtitle}</div>}
    </div>
  )
}

Title.propTypes = {
  title: PropTypes.string.isRequired,
  titleClass: PropTypes.string,
  subtitle: PropTypes.string,
}

Title.defaultProps = {
  title: null,
  titleClass: null,
  subtitle: null,
}

export default Title
